import _ from 'lodash';
import dynamic from 'next/dynamic';

import { FiArrowRightCircle } from 'react-icons/fi';
import { convertDatasets } from 'src/utils/datasets';
import styled from 'styled-components';
import WidgetScore from '../widgets/widget-score';
const CardV = dynamic(import('src/components/cards/card-v'));

const SectionV = ({
  data,
  spacial,
  showSpacial = false,
  count = 4,
  showheading = true,
  heading,
  path = '#',
  more = true,
  sectionEvent,
  sectionCol = 'col-lg-3 col-md-6 col-6 spacial-card',
  badge = true,
  eventDataLayer,
  isSustain = false
}) => {
  if (_.isEmpty(data)) return <div className='default-section-v' />;
  const checkHeading = heading === `${sectionEvent && sectionEvent[1]?.value}`;
  return (
    <>
      {!_.isEmpty(data) && (
        <SectionWrapper className={`block-wrapper ${checkHeading ? 'block-heading-container' : ''}`}>
          {showheading && (
            <BlockWrapper className='block-title'>
              {checkHeading ? (
                <ImageWrapper className='heading-image section-event-5'>
                  <a href={path || '#'}>
                    <h2>{heading}</h2> <img src={_.find(sectionEvent, { key: 'image' })?.value ? _.find(sectionEvent, { key: 'image' })?.value : ''} alt={heading || 'heading'} width='100%' height='100%' />
                  </a>
                  {_.find(sectionEvent, { key: 'image2' })?.value && <img className='mb-1' src={_.find(sectionEvent, { key: 'image2' })?.value} alt={heading || 'heading'} width='100%' height='100%' />}
                </ImageWrapper>
              ) : (
                <h2>{heading}</h2>
              )}
              {(more && !checkHeading) && (
                <a href={path} className='more'>
                  ดูทั้งหมด <FiArrowRightCircle />
                </a>
              )}
            </BlockWrapper>
          )}
          <div className={`row block-content ${checkHeading ? 'block-heading' : ''}`}>
            {/* {showSpacial && (
            )} */}
            {showSpacial && (
              <>
                <div className='spacial-score'>
                  <div className='heading-spacial'>
                    <div className='spacial-logo'>
                      <img src={convertDatasets(spacial?.dataSet, 'image-logo', true)} alt='spacial-logo' title='spacial-logo' width='200' height='100%' />
                    </div>
                    <p className='spacial-date'>{convertDatasets(spacial?.dataSet, 'รายละเอียด', true)}</p>
                  </div>
                  <WidgetScore id='widget-score' data={spacial?.dataSet} />
                </div>
              </>
            )}
            {data.slice(0, count).map((item, index) => (
              <div key={index} className={`${sectionCol} --item-${index + 1}`}>
                <CardV
                  data={item}
                  description={false}
                  badge={badge}
                  path={item.link}
                  //! DATALAYER
                  eventDataLayer={{
                    ...eventDataLayer,
                    data: {
                      ...eventDataLayer?.data,
                      title: item?.title,
                      index: index + 1
                    }
                  }}
                />
              </div>
            ))}
            {/* {more && !isSustain && (
                <MoreButton href={path} className='more'>
                  ดูทั้งหมด <FiArrowRightCircle />
                </MoreButton>
            )} */}
          </div>
          {(_.find(sectionEvent, { key: 'footer-image-left' })?.value && !_.isEmpty(sectionEvent)) && (
            <div className='custom-footer-image'>
              <img className='footer-image-left' src={_.find(sectionEvent, { key: 'footer-image-left' })?.value } alt={heading || 'footer-image-left'} width='100%' height='100%' />
              <a href={path} className='more'>
                  ดูทั้งหมด <FiArrowRightCircle />
                </a>
            </div>
          )}
          {showSpacial && (
            <div className='spacial-link'>
              <a href={path} className='more'>
                <p>ดูทั้งหมด</p> <FiArrowRightCircle />
              </a>
            </div>
          )}
        </SectionWrapper>
      )}
    </>
  );
};
const ImageWrapper = styled.div`
  display: flex;
  gap: 20px;
  align-items: center;
  margin-bottom: 5px;
  a img {
    height: auto !important;
    margin-bottom: 0;
  }
  @media (max-width: 767px) {
    /* width: 100%; */
    justify-content: center;
    margin-bottom: 5px;
  }
`;
const MoreButton = styled.a`
  text-align: end;
  width: 100%;
  @media (max-width: 992px) {
    margin-top: -15px !important;
    margin-bottom: 15px;
  }
`;
const BlockWrapper = styled.div`
  gap: 15px 0;
`;
const SectionWrapper = styled.div`
  @media (max-width: 767px) {
     margin-top: 0;
  }
  .custom-footer-image {
    display: flex;
    justify-content: space-between;
    margin-top: 15px;
    @media (max-width: 767px) {
      margin-top: 5px;
      flex-direction: column;
      align-items: flex-end;
    }
  }

  .footer-image-left {
    max-width: 700px;
    width: 100%;
    @media (max-width: 767px) {
      margin-bottom: 10px;
    }
  }
`;
export default SectionV;
